.app {
  width: 100%;
  height: 100%;
  background: #000000;
  color: #fff;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans:100,400,700,900');

#root,
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  background: #000;
}

html {
  font-size: 14px;
}

ul,
ol,
li,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  color: inherit;
}
