.users-list {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  &__header {
    margin-bottom: 0.357rem;
    font-weight: 700;
  }
  &__filter-block {
    margin-bottom: 1.786rem;
    padding: 0.57rem 1.143rem;
    background-color: #005534;
    border-radius: 5px;
  }
  &__row {
    display: flex;
  }
  &__input-wrapper {
    position: relative;
    margin-right: 0.714rem;
    display: flex;
    justify-content: center;
  }
  &__search-icon {
    position: absolute;
    left: 0.85rem;
    top: calc(50% - 0.72rem);
    &-svg {
      display: block;
      width: 1.44rem;
    }
  }
  &__button {
    flex-grow: 0;
  }

  &__subheader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
    margin-bottom: 1.25rem;
    &-text,
    &-count {
      width: 100%;
      height: 1.4286rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.8571rem;
      padding: 0 0.9rem;
      box-sizing: border-box;
    }
    &-text {
      color: #fff;
      background: #585858;
    }
  }
  &__bold-text {
    font-weight: bold;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.875rem;
    height: 2.5625rem;
    padding: 1.5rem;
    box-sizing: border-box;
    background: #007953;
    border-radius: 5px;
    margin-bottom: 4px;
    &-balance {
      margin-right: 0.7rem;
      font-weight: bold;
    }
    &-right {
      display: flex;
      align-items: center;
    }
    * {
      fill: #fff;
    }
  }

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    &-svg {
      width: 1.0714rem;
      height: 1.0714rem;
    }
  }

  &__list {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  &__user-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.875rem;
    height: 2.5625rem;
    padding: 0.5rem;
    box-sizing: border-box;
    background: #005534;
    border-radius: 5px;
    color: #fff;
    width: 50%;
    margin-right: 1rem;
    &-balance {
      margin-right: 0.7rem;
      font-weight: bold;
    }
    &-right {
      display: flex;
      align-items: center;
    }
  }

  &__account-balance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.875rem;
    height: 2.5625rem;
    padding: 0.5rem;
    box-sizing: border-box;
    background: #005534;
    border-radius: 5px;
    color: #fff;
    width: 50%;
    &-balance {
      margin-right: 0.7rem;
      font-weight: bold;
    }
    &-right {
      display: flex;
      align-items: center;
    }
  }
}
