.input {
  width: 100%;
  min-height: 2.286rem;
  text-align: left;
  box-sizing: border-box;
  outline: 0;
  padding: 0.5rem 1rem;
  background: #ffffff;
  border: 1px solid #007953;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 700;
  font-family: 'Open Sans', 'sans-serif';

  &_sizable {
    &_medium {
      height: 3rem;
    }
  }
}
