.locale {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  &__title {
    background: #005534;
    border-radius: 5px;
    width: 90%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    color: #fff;
    padding-left: 0.7rem;
    box-sizing: border-box;
    margin-bottom: 8px;
    margin-top: 2rem;
  }
  &__item {
    width: 80%;
    height: 2.8rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 0.7rem;
    margin-bottom: 4px;
    background: #007953;
    border-radius: 5px;
    color: #fff;
    &_active {
      color: #ffd200;
    }
  }
  &__image {
    width: 1.4rem;
    margin-right: 0.5rem;
  }
  &__bottom {
    box-sizing: border-box;
    padding: 0.7em 1em;
    width: 65%;
  }
  &__button {
    margin-top: 3rem;
    width: 100%;
    height: 2.8rem;
    font-weight: 700;
    color: #fff;
    background: #f5af21;
    box-shadow: 0px 0px 30px rgba(255, 210, 0, 0.67);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
  }
  &__ok {
    width: 1.8rem;
    position: absolute;
    right: 1rem;
  }
}
