.total {
  padding: 0.5rem 1rem;

  &__title {
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    color: #fff;
    padding-left: 0.7rem;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 1rem;
    justify-content: center;
  }
  &__input-block {
    padding: 0.5rem;
    margin-top: 0.4rem;
    box-sizing: border-box;
    font-weight: 700;
  }
  &__date-wrapper {
    position: relative;
    input {
      background: #007953;
      border-radius: 0.3125rem;
      color: #fff;
      &::-webkit-calendar-picker-indicator {
        background: transparent;
        color: transparent;
        cursor: pointer;
        height: auto;
        position: absolute;
        z-index: 2;
        right: 0;
        top: 0;
        bottom: 0;
        width: 4rem;
      }
    }
  }
  &__icon {
    position: absolute;
    z-index: 1;
    right: 1.28rem;
    top: 50%;
    transform: translateY(-50%);
    &-svg {
      display: block;
      width: 1.5rem;
    }
  }
  &__image {
    width: 1.4rem;
    margin-right: 0.5rem;
  }
  &__bottom {
    box-sizing: border-box;
    padding: 0.7em 1em;
  }
  &__button {
    position: relative;
    height: 2.625rem;
    width: 64%;
    margin: 0.5rem auto;
    cursor: pointer;
  }
  &__ok {
    width: 1.8rem;
    position: absolute;
    right: 1rem;
  }
  &__result-header {
    background: #fff;
    height: 3rem;
    background: #007953;
    box-sizing: border-box;
    color: #fff;
    border-radius: 0.3125rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 0.7rem;
    font-weight: 700;
    margin-bottom: 1.25rem;
  }
  &__result {
    padding: 0 0.4rem;
    box-sizing: border-box;
  }
  &__row {
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 0.7rem;
    font-weight: 700;
    background: #007953;
    box-sizing: border-box;
    color: #fff;
    border-radius: 0.3125rem;
    margin-bottom: 0.5rem;
  }
  &__td {
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    overflow: auto;

    &_type_left {
      width: 40%;
      justify-content: flex-start;
    }
  }
}
