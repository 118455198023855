.button {
  border-radius: 5px;
  width: 100%;
  min-height: 2.286rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0 1.5rem;
  border: none;
  white-space: nowrap;
  font-size: 1rem;
  font-weight: bold;
  background-color: #f5af21;
  box-shadow: 0px 0px 20px #f5af21;

  &:active {
    transform: translateY(2px);
  }

  user-select: none;
  text-align: center;

  &_colors {
  }

  &_sizable {
    &_default {
      height: 100%;
    }
    &_low {
      height: 2.5rem;
    }
    &_high {
      height: 3rem;
    }
  }

  &_fontSize {
    &_low {
      font-size: 1rem;
    }
    &_medium {
      font-size: 1.125rem;
    }
    &_big {
      font-size: 1.25rem;
    }
  }

  &_disable {
    background: gray;
    box-shadow: 0rem 0rem 1.25rem gray;
    &:hover {
      background: gray;
      cursor: not-allowed;
    }
  }

  &_upperCased {
    text-transform: uppercase;
  }
}
