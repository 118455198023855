.inactive {
  position: fixed;
  right: 6px;
  top: 2%;
  width: 100%;
  padding: 1rem;
  z-index: 1000;
  background: #1b1b1b;
  border: 1px solid #ffdf38;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  text-align: center;
  font-size: 1.4rem;
  &__button-container {
    width: 60%;
  }
}
