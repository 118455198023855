.transaction-history {
  padding-bottom: 2rem;
  padding: 0.5rem 1rem;
  &__filter {
    &-block {
    }
    &-item {
      padding: 1rem 0.5rem 0.5rem 0.5rem;
    }
    &-title {
      color: #fff;
      margin-bottom: 0.5rem;
      font-weight: bold;
      font-size: 0.875rem;
    }
  }

  &__date {
    cursor: pointer;
    font-weight: 700;
  }

  &__input {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    input {
      background: #007953;
      border-radius: 0.3125rem;
      color: #fff;
      &::-webkit-calendar-picker-indicator {
        filter: invert(1);
      }
    }
  }

  &__calendar {
    position: absolute;
    top: calc(50% - 0.75rem);
    right: 0.5rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &-svg {
      display: block;
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
    height: 100%;
  }

  .react-datepicker__input-container {
    height: 100%;
    input {
      width: 100%;
      height: 3rem;
      outline: none;
      border: none;
      padding: 0;
      padding-left: 1.5rem;
      box-sizing: border-box;
    }
  }

  &__search-button {
    height: 3rem;
    width: 64%;
    margin: 3rem auto;
  }

  &__search-button-icon {
    position: absolute;
    top: calc(50% - 0.78rem);
    right: 0.5rem;
    width: 1.5714rem;
    height: 1.5714rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 100%;
    &-svg {
      width: 1.1429rem;
      height: 1.1429rem;
      * {
        fill: #fff;
      }
    }
  }

  // &__search-block {
  //   padding: 0.7rem;
  //   position: relative;
  //   margin-bottom: 1rem;
  // }

  &__search-block {
    margin-bottom: 1.786rem;
    padding: 0.57rem 1.143rem;
    background-color: #005534;
    border-radius: 0.3125rem;
    display: flex;
  }

  &__input-wrapper {
    position: relative;
    margin-right: 0.714rem;
    display: flex;
    justify-content: center;
  }

  &__search-icon {
    position: absolute;
    left: 0.85rem;
    top: calc(50% - 0.72rem);
    &-svg {
      display: block;
      width: 1.44rem;
    }
  }

  &__button {
    flex-grow: 0;
  }

  &__search-icon {
    position: absolute;
    left: 1.2rem;
    top: calc(50% - 0.5rem);

    &-svg {
      width: 1rem;
      * {
        fill: rgba(0, 0, 0, 0.5);
      }
    }
  }

  &__users-header {
    height: 3rem;
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 700;
    padding: 0 1rem;
    box-sizing: border-box;
    background: #007953;
    box-sizing: border-box;
    color: #fff;
    border-radius: 0.3125rem;
    margin-bottom: 1rem;
  }
  &__result-wrapper {
    padding: 0 0.1875rem;
  }

  &__user-header-item {
    font-size: 0.875rem;
    width: 100%;
    &_type {
      &_center {
        text-align: center;
      }
      &_right {
        text-align: right;
        max-width: 20%;
      }
    }
  }

  &__total {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;
  }
  &__total-item {
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__total-value {
    margin-left: 0.5rem;
    font-size: 1.1rem;
    font-weight: 700;
  }
}
