.notification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 3.25rem;
  position: relative;
  z-index: 100;
  margin-top: 1rem;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 700;
  box-shadow: 0.2rem 0.2rem 1rem rgba(0, 0, 0, 0.5);
  animation: 0.7s ease init;
  background: #007953;
  border-radius: 0.625rem;
  border: 1px solid #005534;

  &_deleting {
    animation: 0.7s ease delete;
  }

  &__icon {
    margin-right: 1rem;
    &-svg {
      display: block;
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  &__left {
    align-self: flex-start;
    box-sizing: border-box;
    flex-shrink: 0;
  }

  &__cross {
    display: block;
    width: 0.8rem;
    margin-top: 0.75rem;
    margin-right: 0.5rem;
    cursor: pointer;

    * {
      fill: #1c9868;
    }
  }

  &__text {
    flex-grow: 2;
    box-sizing: border-box;
    padding: 0 1rem;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    word-break: break-word;
  }

  &__wrapper {
    width: 100%;
    min-height: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 0.2rem 0;
    box-sizing: border-box;
  }

  &__wrapper_type {
    background: #007953;
  }

  @keyframes init {
    0% {
      opacity: 0;
      transform: translateX(10rem);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes delete {
    0% {
      opacity: 1;
      height: 3.25rem;
    }
    70% {
      opacity: 0.5;
      transform: translateX(120%);
      height: 3.25rem;
    }
    100% {
      opacity: 0;
      transform: translateX(200%);
      height: 0;
    }
  }
}
