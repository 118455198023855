.password-pop-up {
  position: absolute;
  color: #fff;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 5;
  font-size: 1rem;
  font-weight: bold;
  &__body {
    background: #007953;
    /* джолтон */
    border: 1px solid #005534;
    box-sizing: border-box;
    border-radius: 10px;
    width: 65%;
    height: 12rem;
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  &__header {
    width: 100%;
    text-align: center;
    margin-bottom: 0.7rem;
  }
  &__password {
    font-weight: bold;
    font-size: 1.2857rem;
    text-align: center;
    margin-bottom: 0.7rem;
  }

  &__button {
    width: 80%;
    background: #ffd200;
    box-shadow: 0px 0px 30px rgba(255, 210, 0, 0.67);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 2.5rem;
    color: #000;
    cursor: pointer;
  }
}
